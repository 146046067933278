/* FUNCTIONS
  --------------------------------------- */
@function gutter-scale($size) {
  @return map-get($base-gutter-scales, $size);
}
@function gutter-height($breakpoint) {
  @return map-get($base-gutter-heights, $breakpoint);
}
@function map-deep-get($map, $keys...) {
  @each $key in $keys {
    $map: map-get($map, $key);
  }
  @return $map;
}
@function create_color_map($bg, $text, $headline, $link, $hover, $icon, $iconhover, $headlinealt, $btntext, $btnarrow, $btnhover, $strokebtn, $strokebtntext, $strokebtntexthover) {
  $map: (
    bg:                 $bg,
    text:               $text,
    headline:           $headline,
    link:               $link,
    hover:              $hover,
    icon:               $icon,
    iconhover:          $iconhover,
    headlinealt:        $headlinealt,
    btntext:            $btntext,
    btnarrow:           $btnarrow,
    btnhover:           $btnhover,
    strokebtn:          $strokebtn,
    strokebtntext:      $strokebtntext,
    strokebtntexthover: $strokebtntexthover,
  );
  @return $map;
}

/* HEIGHT SPACERS
  --------------------------------------- */
@mixin scaled-spacer($csstype,$csslocation,$scale) {
  #{$csstype}-#{$csslocation}: gutter-height(base)*gutter-scale($scale);
}
@mixin scaled-spacer-negative($csstype,$csslocation,$scale) {
  #{$csstype}-#{$csslocation}: -(gutter-height(base)*gutter-scale($scale)) !important;
}
@mixin scaled-absolute($csslocation,$scale) {
  #{$csslocation}: (gutter-height(base)*gutter-scale($scale));
}
@mixin scaled-absolute-negative($csslocation,$scale) {
  #{$csslocation}: -(gutter-height(base)*gutter-scale($scale));
}
@mixin full-padding-spacer($size) {
  @include scaled-spacer('padding','top',$size);
  @include scaled-spacer('padding','right',$size);
  @include scaled-spacer('padding','bottom',$size);
  @include scaled-spacer('padding','left',$size);
}

/* COLORS
  --------------------------------------- */

/*1 name          2 bgcolor       3 text        4 headline      5 link color      6 hover color     7 icon      8 icon-hover    9 headline-alt  10 btntext  11 btnarrow   12 btnhover   13 strokebtn  14 strokebtntext  15 strokebtntexthover  */
$color-list: (
  white           $white          $body-color   $green-bright   $green-bright     $green            $orange     $yellow         $green          $white      $white        $white        $white        $white            $white,
  black           $black          $white        $white          $white            $gray-light       $orange     $yellow         $gray-light     $white      $white        $white        $white        $white            $white,
  black-near      $black-near     $white        $white          $white            $gray-light       $orange     $yellow         $gray-light     $white      $white        $white        $white        $white            $white,
  orange          $orange         $black-near   $black-near     $gray             $gray-light       $white      $yellow         $black          $green      $green        $orange       $orange       $green            $green,
  yellow          $yellow         $black-near   $black-near     $gray             $gray-light       $white      $white          $black          $green      $green        $yellow       $yellow       $green            $green,
  yellow-light    $yellow-light   $black-near   $black-near     $green            $green-bright     $orange     $green          $black          $green      $green        $yellow       $yellow       $green            $green,
  green           $green          $white        $white          $white            $orange           $yellow     $orange         $gray-light     $white      $orange       $green-bright $green-bright $green            $white,
  green-bright    $green-bright   $white        $white          $white            $gray-light       $yellow     $yellow         $gray-light     $white      $white        $white        $white        $white            $white,
  blue            $blue           $body-color   $green          $green-bright     $green            $yellow     $yellow         $green-bright   $white      $white        $white        $white        $white            $white,
  blue-light      $blue-light     $body-color   $green-bright   $green-bright     $green            $orange     $yellow         $green          $white      $white        $white        $white        $white            $white,
  gray            $gray           $body-color   $green-bright   $green-bright     $green            $yellow     $yellow         $green          $white      $white        $white        $white        $white            $white,
  gray-light      $gray-light     $body-color   $green          $green-bright     $green            $orange     $yellow         $green-bright   $white      $white        $white        $white        $white            $white,
  red             $red            $white        $white          $white            $blue-light       $white      $blue-light     $gray-light     $white      $white        $white        $white        $white            $white,
);
$color-map: ();

@each $value in $color-list {
  .bg-#{nth($value, 1)},
  .has-#{nth($value, 1)}-background-color {
    background-color: #{nth($value, 2)};
    color: #{nth($value, 3)};
    h1,.h1,h2,.h2,h3,.h3,h4,.h4,h5,.h5,h6,.h6,blockquote,label {
      color: #{nth($value, 4)};
    }
    a:not(.btn) {
      color: #{nth($value, 5)};
      @include hover-focus {
        color: #{nth($value, 6)};
      }
      &.icon-link {
        color: #{nth($value, 7)};
        @include hover-focus {
          color: #{nth($value, 8)};
        }
      }
    }
    .header-color {
      color: #{nth($value, 4)};
    }
    .header-color-alt {
      color: #{nth($value, 9)};
    }
    .fa-icon-color {
      color: #{nth($value, 7)};
    }
    .circle-icon {
      background-color: #{nth($value, 8)};
    }
    blockquote {
      border-left-color: #{nth($value, 9)};
    }
    .form-control.underlined {
      border-bottom-color: #{nth($value, 7)};
      color: #{nth($value, 3)};
    }
    .readmore {
      color: #{nth($value, 9)};
      &:after {
        color: #{nth($value, 7)};
      }
    }
  }
  .btn {
    &.btn-#{nth($value, 1)} {
      background-color: #{nth($value, 2)};
      color: #{nth($value, 10)};
      &:after {
        color: #{nth($value, 11)};
      }
      @include hover-focus {
        background-color: $white;
        color: $green;
        border-color: #{nth($value, 12)};
      }
/*
      &:active {
        background-color: #{nth($value, 12)};
      }
*/
      &.btn-stroke {
        border-left-color: #{nth($value, 13)};
        color: #{nth($value, 14)};
        &:after {
          color: #{nth($value, 14)};
        }
        @include hover-focus {
          background-color: transparent;
          color: #{nth($value, 14)};
          border-color: #{nth($value, 13)};
        }
/*
        &:active {
          background-color: #{nth($value, 13)};
          color: #{nth($value, 15)};
          &:after {
            color: #{nth($value, 15)};
          }
        }
*/
      }
    }
  }
  $map: ();
  $key: #{nth($value, 1)};
  $map: map-merge($map, ($key: create_color_map(#{nth($value, 2)}, #{nth($value, 3)},#{nth($value, 4)},#{nth($value, 5)},#{nth($value, 6)},#{nth($value, 7)},#{nth($value, 8)},#{nth($value,9)},#{nth($value,10)},#{nth($value,11)},#{nth($value,12)},#{nth($value,13)},#{nth($value,14)},#{nth($value,15)})) );
  $color-map: map-merge($color-map, $map);
}
@mixin color-block($block-color) {
  background-color: map-deep-get($color-map, $block-color, "bg");
  color: map-deep-get($color-map, $block-color, "text");
  h1,.h1,h2,.h2,h3,.h3,h4,.h4,h5,.h5,h6,.h6,blockquote,label {
    color: map-deep-get($color-map, $block-color, "headline");
  }
  a:not(.btn) {
    color: map-deep-get($color-map, $block-color, "link");
    @include hover-focus {
      color: map-deep-get($color-map, $block-color, "hover");
    }
    &.icon-link {
      color: map-deep-get($color-map, $block-color, "icon");
      @include hover-focus {
        color: map-deep-get($color-map, $block-color, "iconhover");
      }
    }
  }
  .header-color {
    color: map-deep-get($color-map, $block-color, "headline");
  }
  .header-color-alt {
    color: map-deep-get($color-map, $block-color, "headlinealt");
  }
  .fa-icon-color {
    color: map-deep-get($color-map, $block-color, "icon");
  }
  .circle-icon {
    background-color: map-deep-get($color-map, $block-color, "iconhover");
  }
  .form-control.underlined {
    border-bottom-color: map-deep-get($color-map, $block-color, "icon");
    color: map-deep-get($color-map, $block-color, "text");
    &::-webkit-input-placeholder {
		  color: map-deep-get($color-map, $block-color, "text");
		}
		&:-moz-placeholder { /* Firefox 18- */
		  color: map-deep-get($color-map, $block-color, "text");
		}
		&::-moz-placeholder {  /* Firefox 19+ */
		  color: map-deep-get($color-map, $block-color, "text");
		}
		&:-ms-input-placeholder {
		  color: map-deep-get($color-map, $block-color, "text");
		}
  }
  button.stripped {
    color: map-deep-get($color-map, $block-color, "icon");
    @include hover-focus {
      color: map-deep-get($color-map, $block-color, "iconhover");
    }
  }
  .readmore {
    color: map-deep-get($color-map, $block-color, "headlinealt");
    &:after {
      color: map-deep-get($color-map, $block-color, "icon");
    }
  }
  blockquote {
    border-left-color: map-deep-get($color-map, $block-color, "headlinealt");
  }
}
@mixin btn-color($btn-color) {
  background-color: map-deep-get($color-map, $block-color, "bg");
  color: map-deep-get($color-map, $block-color, "btntext");
  &:after {
    color: map-deep-get($color-map, $block-color, "btnarrow");
  }
  @include hover-focus {
    background-color: $white;
    color: $green;
    border-color: map-deep-get($color-map, $block-color, "btnhover");
  }
  &:active {
    background-color: map-deep-get($color-map, $block-color, "btnhover");
  }
  &.btn-stroke {
    border-left-color: map-deep-get($color-map, $block-color, "strokebtn");
    color: map-deep-get($color-map, $block-color, "strokebtntext");
    &:after {
      color: map-deep-get($color-map, $block-color, "strokebtntext");
    }
    @include hover-focus {
      background-color: transparent;
      color: map-deep-get($color-map, $block-color, "strokebtntext");
      border-color: map-deep-get($color-map, $block-color, "strokebtn");
    }
    &:active {
      background-color: map-deep-get($color-map, $block-color, "strokebtn");
      color: map-deep-get($color-map, $block-color, "strokebtntexthover");
      &:after {
        color: map-deep-get($color-map, $block-color, "strokebtntexthover");
      }
    }
  }
}


/* ELEMENTS
  --------------------------------------- */
@mixin d-flex {
  display: flex !important;
}
@mixin list-normalize-keep-margin {
  padding: 0;
  list-style: none;
}
@mixin list-normalize {
  @include list-normalize-keep-margin
  margin: 0;
}
@mixin list-inline-block {
  li {
    display: inline-block;
  }
}
@mixin last-child-zero-margin {
  &>:last-child {
    margin-bottom: 0;
  }
}
@mixin paragraph-spacing {
  margin-bottom: 1.5rem;
}
@mixin link-underline {
  text-decoration: underline;
  @include hover-focus {
    text-decoration: underline;
  }
}
@mixin link-no-underline {
  @include hover-focus {
    text-decoration: none;
  }
}
@mixin no-padding {
  padding: 0;
}
@mixin bgimg-btm($max-width) {
  background-position: 50% 101%;
  background-repeat: no-repeat;
  background-size: $max-width auto;
  @media screen and (min-width: $max-width) {
    background-size: 100% auto;
  }
}
@mixin page-header-frame {
  position: relative;
  hr {
    width: 100%;
    height: 43px;
    background: transparent url(../images/bg/mountains-btm-white.png) repeat-x 50% 0;
    background-size: auto 43px;
    margin: 0;
    border: none;
    position: absolute;
    bottom: 0;
    left: 0;
  }
}
@mixin page-header-frame-home {
  @include page-header-frame;
  hr {
    height: 78px;
    background-image: url(../images/bg/mountains-btm-featured-white.png);
    background-size: auto 78px;
  }
}

@mixin featured-image-frame($color) {
  position: relative;
  border-top-left-radius: $image-frame-radius;
  border-top-right-radius: $image-frame-radius;
  img {
    border-top-left-radius: $image-frame-radius;
    border-top-right-radius: $image-frame-radius;
  }
  hr {
    margin: 0;
    border: none;
    position: absolute;
    left: 0;
    bottom: -1px;
    width: 100%;
    height: 78px;
    background: transparent url(../images/bg/mountains-btm-featured-#{$color}.png) no-repeat 0 100%;
    background-size: 101% auto;
  }
}
@mixin fsf-pills {
  @include scaled-spacer('padding','top','xs-xx');
  @include scaled-spacer('padding','bottom','xs-xx');
  @include scaled-spacer('padding','left','sm');
  @include scaled-spacer('padding','right','sm');
  @include color-block('blue-light');
  @include link-no-underline;
  @include overline-sm;
  display: inline-block;
  border-radius: 10px;
  box-shadow: 0px 2px 2px rgba($black, 0.05);
  line-height: 1;
  @include hover-focus {
    @include color-block('yellow');
  }
  &.active {
    @include color-block('yellow');
  }
}
@mixin category-filters {
  @include scaled-spacer('margin','bottom','md');
  .filters {
    @include list-normalize;
    @include list-inline-block;
    li {
      @include scaled-spacer('margin','right','xs');
      @include scaled-spacer('margin','bottom','xs');
      a {
        @include fsf-pills;
      }
    }
  }
}

@mixin btn-smallify {
  font-size: $btn-font-size-sm;
  padding: $btn-padding-y-sm $btn-padding-x-sm;
  padding-right: $btn-padding-x-sm*2.25;
  &:after {
    right: $btn-padding-x-sm*.9;
  }
  @include hover-focus {
    padding-left: $btn-padding-x-sm*.85;
    padding-right: $btn-padding-x-sm*2.4;
    &:after {
      right: $btn-padding-x-sm*.75;
    }
  }
  &.btn-back {
    padding-right: $btn-padding-x-sm;
    padding-left: $btn-padding-x-sm*2.25;
    &:after {
      right: auto;
      left: $btn-padding-x-sm*.9;
    }
    @include hover-focus {
      padding-right: $btn-padding-x-sm*.85;
      padding-left: $btn-padding-x-sm*2.4;
      &:after {
        right: auto;
        left: $btn-padding-x-sm*.75;
      }
    }
  }
}
/* NAVS
  --------------------------------------- */
@mixin nav-horizontal {
  &>ul {
    @include list-normalize;
    li {
      @include scaled-spacer('padding','left','xs-xx');
      @include scaled-spacer('padding','right','xs-xx');
      @include media-breakpoint-up(xl) {
        @include scaled-spacer('padding','left','xs-sm');
        @include scaled-spacer('padding','right','xs-sm');
      }
      padding-top: 0;
      padding-bottom: 0;
      margin: 0;
      text-align: center;
      line-height: 1;
      display: inline-block;
    }
  }
  &:not(.nav-ancillary):not(.nav-primary) {
    &>ul {
      li {
        &:first-child {
          padding-left: 0;
        }
        &:last-child {
          padding-right: 0;
        }
      }
    }
  }
}
@mixin nav-piped {
  @include nav-horizontal;
  &>ul {
//     margin-left: -1px;
    li {
      @include scaled-spacer('padding','left','sm');
      @include scaled-spacer('padding','right','sm');
      border-left-width: 1px;
      border-left-style: solid;
      line-height: 1;
    }
  }
  &:not(.nav-ancillary) {
    &>ul {
      li {
        &:first-child {
          border-left: none;
        }
      }
    }
  }
}


/* FONTS
  --------------------------------------- */
@mixin sans-text {
  font-family: $font-family-sans;
}
@mixin serif-text {
  font-family: $font-family-serif;
}
@mixin allcaps {
  text-transform: uppercase;
  letter-spacing: $spaced-lettering;
}
@mixin overline {
  @include allcaps;
  @include sans-text;
  font-weight: $font-weight-black;
  font-size: $font-size-md;
}
@mixin overline-sm {
  @include overline;
  font-size: $font-size-xxs;
}
$heading-sizes: (
  h1: $h1-font-size,
  h2: $h2-font-size,
  h3: $h3-font-size,
  h4: $h4-font-size,
  h5: $h5-font-size,
  h6: $h6-font-size
);
@mixin h1-display-lg {
  font-weight: $font-weight-semi;
  font-size: $h2-font-size;
  line-height: 1.1;
  @include media-breakpoint-up(lg) {
    font-size: $h1-display-lg;
  }
}
@mixin h1-display-md {
  font-weight: $font-weight-semi;
  font-size: $h2-font-size;
  @include media-breakpoint-up(lg) {
    font-size: $h1-display-md;
  }
}
@mixin resize-header($size) {
  font-size: map-get($heading-sizes, $size);
}
@mixin fa-font-solid {
  font: var(--fa-font-solid);
}
@mixin fa-font-regular {
  font: var(--fa-font-regular);
}
@mixin fa-font-light {
  font: var(--fa-font-light);
}
@mixin fa-font-brand {
  font: var(--fa-font-brand);
}
@mixin label {
  @include overline-sm;
  @include scaled-spacer('margin','bottom','xss');
}

/* COLUMNS
  --------------------------------------- */
@mixin wp-full-width-no-padding {
  width: 100vw;
  max-width: 100vw;
  margin: 0;
  margin-left: calc(50% - 50vw);
}
@mixin main-column-width {
  @include make-col-ready();
  @include make-col(12);
  @include media-breakpoint-up(md) {
    @include make-col(10);
    @include make-col-offset(1);
  }
  @include media-breakpoint-up(lg) {
    @include make-col(8);
    @include make-col-offset(2);
  }
}
@mixin main-column-width-wide {
  @include make-col-ready();
  @include make-col(12);
  @include media-breakpoint-up(md) {
    @include make-col(12);
    @include make-col-offset(0);
  }
  @include media-breakpoint-up(lg) {
    @include make-col(10);
    @include make-col-offset(1);
  }
}
@mixin main-column-width-full {
  @include make-col-ready();
  @include make-col(12);
  @include media-breakpoint-up(md) {
    @include make-col(12);
    @include make-col-offset(0);
  }
  @include media-breakpoint-up(lg) {
    @include make-col(12);
    @include make-col-offset(0);
  }
}
