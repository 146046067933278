// Grid system
.main {
  @include make-col-ready();
  @include media-breakpoint-up(sm) {
    @include make-col($main-sm-columns);
    .sidebar-primary & {
      @include make-col($main-sm-columns - $sidebar-sm-columns);
    }
  }
}
.sidebar {
  @include make-col-ready();
  @include media-breakpoint-up(sm) {
    @include make-col($sidebar-sm-columns);
  }
}
body {
  &:not(.blog):not(.post-type-archive-tribe_events):not(.single-tribe_events):not(.single-leadership) {
    .main {
      @include main-column-width;
    }
  }
  &.blog {
    .main {
      @include main-column-width-full;
    }
  }
  &.single-tribe_events,&.post-type-archive-tribe_events,&.single-leadership {
    .main {
      @include main-column-width-wide;
    }
  }
}
