.btn,a.btn {
  @include transition($link-transition-btn);
  @include allcaps;
  border: 4px solid transparent;
  background-color: $btn-bg;
  color: $btn-color;
  border-radius: $btn-radius;
  position: relative;
  padding-right: $btn-padding-x*2.25;
  &:after {
    @include transition($link-transition-btn);
    @include fa-font-solid;
    content: "\f178";
    position: absolute;
    right: $btn-padding-x*.9;
    top: 50%;
    transform: translateY(-50%);
  }
  @include hover-focus {
    padding-left: $btn-padding-x*.85;
    padding-right: $btn-padding-x*2.4;
    background-color: $white;
    color: $brand-primary;
    border-color: $btn-bg;
    &:after {
      right: $btn-padding-x*.75;
    }
  }
  &:not(.btn-stroke) {
    &.btn-sm {
      @include media-breakpoint-up(md) {
        @include btn-smallify;
      }
    }
    @include media-breakpoint-down(sm) {
      @include btn-smallify;
    }
  }
  &.btn-back {
    padding: $btn-padding-y $btn-padding-x $btn-padding-y $btn-padding-x*2.25;
    &:after {
      content: "\f177";
      right: auto;
      left: $btn-padding-x*.9;
    }
    @include hover-focus {
      padding-right: $btn-padding-x*.85;
      padding-left: $btn-padding-x*2.4;
      &:after {
        right: auto;
        left: $btn-padding-x*.75;
      }
    }
  }
  &.btn-stroke {
    padding: $btn-padding-y-stroke $btn-padding-x-stroke;
    padding-right: $btn-padding-x-stroke*2.5;
    background-color: transparent;
    border-width: 1px;
    border-left-width: 8px;
    border-radius: 0;
    &:after {
      right: $btn-padding-x-stroke*.6;
    }
    @include hover-focus {
      padding-left: $btn-padding-x-stroke*.85;
      padding-right: $btn-padding-x-stroke*2.65;
      &:after {
        right: $btn-padding-x-stroke*.45;
      }
    }
  }
}
