footer.content-info {
  .grant-info {
    @include color-block('blue');
    @include scaled-spacer('padding','top','lg');
    @include scaled-spacer('padding','bottom','huge');
    @include bgimg-btm(1440px);
    background-image: url(../images/bg/mountains-btm-greens.png);
    font-size: $font-size-lg;
    @include media-breakpoint-up(md) {
      @include scaled-spacer('padding','top','xxxl');
      @include scaled-spacer('padding','bottom','mega');
      h2 {
        text-align: right;
      }
    }
  }
  .company-info {
    @include color-block('green');
    @include scaled-spacer('padding','top','lg');
    @include scaled-spacer('padding','bottom','xxxl');
    @include bgimg-btm(1440px);
    background-image: url(../images/bg/mountains-btm-smooth-white.png);
    @include media-breakpoint-up(md) {
      @include scaled-spacer('padding','top','xl');
      @include scaled-spacer('padding','bottom','xxxxl');
    }
    @include media-breakpoint-up(lg) {
      @include scaled-spacer('padding','bottom','xxxxxl');
    }
    &>.container {
      &>.row {
        &>div {
          article {
            @include scaled-spacer('padding','bottom','md');
            @include scaled-spacer('margin','bottom','md');
            @include last-child-zero-margin;
            border-bottom: 1px solid $green-bright;
          }
          &:last-child {
            article {
              margin-top: 0;
              padding-top: 0;
              border: none;
              height: 100%;
            }
          }
          @include media-breakpoint-up(md) {
            &:not(.donate) {
              article {
                padding: 0;
                margin: 0;
                border: none;
              }
              &.navigation {
                article {
                  padding-right: 10%;
                }
              }
              &.newsletter {
                border-left: 1px solid $green-bright;
                article {
                  padding-left: 10%;
                }
              }
            }
          }
          @include media-breakpoint-up(lg) {
            border-left: 1px solid $green-bright;
            article {
              padding: 0 12% !important;
              margin: 0;
              border: none;
            }
            &:first-child {
              border-left: none;
              article {
                padding-left: 0 !important;
              }
            }
            &:last-child {
              article {
                padding-right: 0 !important;
              }
            }
          }
          @include media-breakpoint-up(xl) {
            article {
              padding: 0 18% !important;
            }
            &:first-child {
              article {
                padding: 0 15% 0 0 !important;
              }
            }
            &:last-child {
              article {
                padding: 0 0 0 15% !important;
              }
            }
          }
        }
      }
    }
    .donate {
      font-size: $font-size-sm;
      h3 {
        @include resize-header('h4');
        @include sans-text;
        font-weight: $font-weight-bold;
      }
    }
    .navigation {
      .footer-links {
        ul {
          @include list-normalize;
          font-size: $font-size-base;
          font-weight: $font-weight-semi;
          line-height: 1.1;
          li {
            @include scaled-spacer('margin','bottom','sm');
            &:last-child {
              margin-bottom: 0;
            }
            a {
              @include link-no-underline;
            }
          }
        }
      }
    }
    .newsletter {
      font-size: $font-size-lg;
      h3 {
        @include resize-header('h5');
        @include overline;
      }
      nav.social {
        @include scaled-spacer('margin','top','md-lg');
        ul {
          @include list-normalize;
          @include list-inline-block;
          font-size: $h3-font-size;
          li {
            @include scaled-spacer('margin','right','sm-sm');
            margin-bottom: 0;
            a {
              color: $yellow;
              @include hover-focus {
                color: $orange;
              }
            }
          }
        }
      }
    }
  }
  .citizen-advocates-info {
    @include color-block('white');
    @include scaled-spacer('padding','top','md-lg');
    @include scaled-spacer('padding','bottom','md');
    @include media-breakpoint-up(lg) {
      @include scaled-spacer('padding','top','lg');
    }
    .media {
      a {
        @include scaled-spacer('margin','bottom','sm');
        display: block;
      }
    }
    .details {
      @include last-child-zero-margin;
      font-size: $font-size-md;
      color: $brand-primary;
    }
  }
  .legal-info {
    @include color-block('white');
    article {
      @include scaled-spacer('padding','top','xs-sm');
      @include scaled-spacer('padding','bottom','sm-md');
      justify-content: center;
      border-top: 1px solid $green-bright;
      font-size: $font-size-xs;
      color: $brand-primary;
      nav {
        @include nav-piped;
        ul {
          li {
            @include scaled-spacer('padding','left','xs');
            @include scaled-spacer('padding','right','xs');
            border-color: $brand-primary;
            a {
              color: $brand-primary !important;
            }
            &:last-child {
              @include scaled-spacer('margin','right','xs');
              padding-right: 8px !important;
              border-right-width: 1px;
              border-right-style: solid;
            }
          }
        }
      }
      .copyright {
        margin-bottom: 0;
      }
    }
  }
}
