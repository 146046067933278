header.banner {
  @include color-block('white');
  @include scaled-spacer('padding','top','sm');
  @include scaled-spacer('padding','bottom','sm');
  height: $header-height-sm;
  @include media-breakpoint-up(md) {
    height: $header-height;
  }
  border-bottom: 1px solid rgba($green-bright,.1);
  z-index: 5;
  position:-webkit-sticky;
  position:sticky;
  top:0;
  .brand {
    position: relative;
    a {
      display: block;
      height: 52px;
      @include media-breakpoint-up(md) {
        height: 82px;
/*
        position: absolute;
        top: -12px;
        left: $grid-gutter-width/2;
*/
        top:8px;
        left: calc(50% - 344px); // 360 (half of 720 container) - 16 (left gutter)
        position: fixed;
      }
      @include media-breakpoint-up(lg) {
        left: calc(50% - 480px); // 496 (half of 992 container) - 16 (left gutter)
      }
      @include media-breakpoint-up(xl) {
        left: calc(50% - 610px); // 626 (half of 1252 container) - 16 (left gutter)
      }
      img {
        height: 100%;
        width: auto;
      }
    }
  }
  .navs {
    justify-content: flex-end;
    align-items: center;
    position: relative;
    .nav-primary {
      &>ul {
        &>li {
          @include scaled-spacer('margin','right','sm-sm');
          margin-bottom: 0;
          a {
            font-weight: $font-weight-bold;
            font-size: $font-size-sm;
            @include full-padding-spacer('xs');
            @include link-no-underline;
          }
          &>a {
            color: $brand-primary !important;
            border: none;
            border-bottom: 2px solid transparent;
            @include hover-focus {
              background-color: $blue-light;
              border-bottom-color: $blue-light;
            }
          }
          &.active {
            &>a {
              color: $brand-primary;
              border-bottom-color: $green-bright;
            }
          }
          &.dropdown {
            .dropdown-toggle:after {
              display: none;
            }
            &:hover {
              &>.dropdown-menu {
                display: block;
              }
            }
            .dropdown-menu {
              background-color: $blue-light;
              margin: 0;
              border: none;
              padding: 8px 0;
              .dropdown-item {
                &.active,&:active {
                  background-color: transparent;
                }
                @include hover-focus {
                  color: $brand-primary;
                  background-color: transparent;
                }
              }
            }
          }
        }
      }
    }
    .navbar-toggler {
      @include scaled-spacer('margin','left','sm');
      position: relative;
      width: $h3-font-size;
      height: $h3-font-size;
      line-height: 1;
      border: none;
      border-radius: 0;
      text-align: center;
      color: $brand-primary;
      @include hover-focus {
        color: $green-bright;
        outline: 0;
      }
      i {
        font-size: $h3-font-size;
        position: absolute;
        top: 0;
        right: 0;
        display: block;
        &.fa-circle-x {
          @include transition($link-transition-bars);
    			transform: rotate(-180deg) scale(.5);
    			opacity: 0;
    			left: 2px;
        }
        &.fa-bars {
          @include transition($link-transition-bars);
        }
        .primary-nav-engaging & {
          &.fa-circle-x {
            transform: rotate(0deg) scale(1);
      			opacity: 1;
          }
          &.fa-bars {
            transform: rotate(180deg) scale(.5);
      			opacity: 0;
          }
        }
      }
    }
  }
  #nav-wrapper {
    .nav-primary {
      &>ul {
        @include list-normalize;
        @include scaled-spacer('margin','top','md-lg');
        @include scaled-spacer('margin','bottom','md-lg');
        text-align: center;
        &>li {
          @include scaled-spacer('margin','bottom','sm-md');
          &>a {
            font-size: $font-size-base;
            font-weight: $font-weight-semi;
            color: $brand-primary;
          }
          ul.sub-menu {
            @include list-normalize;
            @include scaled-spacer('margin','top','xxs');
            li {
              @include scaled-spacer('margin','bottom','xs');
              a {
                font-size: $font-size-sm;
                font-weight: $font-weight-bold;
                color: $green-bright;
              }
            }
          }
        }
      }
    }
    @include media-breakpoint-up(lg) {
      display: none !important;
      visibility: hidden !important;
    }
    .ancillary-items {
      @include scaled-spacer('padding','top','sm-md');
      @include scaled-spacer('padding','bottom','sm-md');
      text-align: center;
      .nav-ancillary {
        @include nav-piped;
        @include scaled-spacer('margin','bottom','sm-md');
        font-size: $font-size-sm;
        text-transform: uppercase;
        font-weight: $font-weight-bold;
        ul {
          li {
            &:first-child {
              border-left: none;
            }
            a {
              color: $brand-primary;
            }
          }
        }
      }
      .search-form {
        input {
          padding: 2px 0;
          &::-webkit-input-placeholder {
            text-transform: uppercase;
      		}
      		&:-moz-placeholder { /* Firefox 18- */
            text-transform: uppercase;
      		}
      		&::-moz-placeholder {  /* Firefox 19+ */
            text-transform: uppercase;
      		}
      		&:-ms-input-placeholder {
            text-transform: uppercase;
      		}
        }
      }
    }
  }
}
.ancillary-items {
  .search-form {
    input {
      order: 1;
      outline: none;
      border: none;
      border-bottom: 1px solid $orange;
      background: none;
      padding: 0;
      color: $brand-primary;
      float: left;
      transition: .5s;
      line-height: 1;
      font-size: $font-size-sm;
      cursor: pointer;
      font-weight: $font-weight-bold;
      &::-webkit-input-placeholder {
        color: $brand-primary;
        font-weight: $font-weight-bold;
  		}
  		&:-moz-placeholder { /* Firefox 18- */
        color: $brand-primary;
        font-weight: $font-weight-bold;
  		}
  		&::-moz-placeholder {  /* Firefox 19+ */
        color: $brand-primary;
        font-weight: $font-weight-bold;
  		}
  		&:-ms-input-placeholder {
        color: $brand-primary;
        font-weight: $font-weight-bold;
  		}
    }
    button {
      @include scaled-spacer('padding','left','xs');
      @include scaled-spacer('padding','right','xs');
      padding-top: 0;
      padding-bottom: 0;
      order: 0;
      font-size: $font-size-sm;
      float: right;
      width: auto;
      height: auto;
      border: none;
      background-color: transparent;
      outline: none;
      display: flex;
      justify-content: center;
      align-items: center;
      text-decoration: none;
      transition: .3s;
      i {
        @include fa-font-solid;

      }
    }
  }
}
.ancillary-header {
  &>.container {
    text-align: right;
  }
  .ancillary-items {
    @include color-block('yellow-light');
    @include full-padding-spacer('xs');
    @include scaled-spacer('padding','top','xxs');
    @include scaled-spacer('padding','bottom','xxs');
    border-radius: 0;
    height: $ancillary-height;
    .search-form {
      input {
        border-bottom: 1px solid transparent;
        width: $searchbar-width-closed;
        font-size: $font-size-xs;
        &:focus,
        &:not(:placeholder-shown) {
          width: $searchbar-width;
          border-bottom-color: rgba($orange,.5);
          cursor: text;
        }
      }
      button {
        color: $brand-teriary;
        font-size: $font-size-xs;
      }
    }
    nav {
      ul {
        @include list-normalize;
        @include list-inline-block;
        li {
          @include scaled-spacer('margin','left','xs');
          margin-bottom: 0;
          a {
            @include full-padding-spacer('xs');
            @include link-no-underline;
            display: block;
            font-size: $font-size-xs;
            font-weight: $font-weight-bold;
            line-height: 1;
            i {
              @include fa-font-solid;
              @include scaled-spacer('margin','right','xs');
              font-size: $font-size-sm;
            }
          }
        }
      }
    }
  }
}
