body {
  &:not(.home) {
    .page-header {
      @include page-header-frame;
    }
  }
  &.home {
    .page-header {
      @include page-header-frame-home;
    }
  }
}
.page-header {
  @include color-block('green');
  position: relative;
  &:not(.post-header) {
    h1.page-title {
      @include h1-display-md;
      margin-bottom: 0;
      .home & {
        @include h1-display-lg;
      }
    }
    &:before {
      content: "";
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
      position: absolute;
      top: 0px;
      right: 0px;
      bottom: 0px;
      left: 0px;
      opacity: .15;
    }
  }
  .details {
    @include make-col-ready();
    @include make-col(12);
    @include scaled-spacer('margin','top','md-lg');
    @include scaled-spacer('margin','bottom','xxxl');
    text-align: center;
    @include media-breakpoint-up(md) {
      @include scaled-spacer('margin','top','xxl');
      @include make-col(10);
      @include make-col-offset(1);
    }
    @include media-breakpoint-up(lg) {
      @include make-col(8);
      @include make-col-offset(2);
    }
    .home & {
      @include media-breakpoint-up(lg) {
        @include make-col(10);
        @include make-col-offset(1);
      }
      @include media-breakpoint-up(xl) {
        @include make-col(8);
        @include make-col-offset(2);
      }
    }
    .entry-meta {
      @include overline;
    }
    .overline,.entry-meta {
      @include scaled-spacer('margin','bottom','xxxs');
    }
    .synopsis {
      @include last-child-zero-margin;
      @include scaled-spacer('margin','top','md');
      @include scaled-spacer('padding','top','md');
      @include serif-text;
      font-weight: $font-weight-med;
      font-size: $font-size-base;
      @include media-breakpoint-up(lg) {
        font-size: $font-size-lg;
      }
      background: transparent url(../images/bg/page-header-accent.png) no-repeat 50% 0;
      background-size: 75px auto;
      @include media-breakpoint-up(xl) {
        padding-left: 12.5%;
        padding-right: 12.5%;
      }
    }
    .home & {
      @include scaled-spacer('margin','top','xxxl');
      @include scaled-spacer('margin','bottom','xxxxl');
      @include media-breakpoint-up(md) {
        @include scaled-spacer('margin','top','xxxxl');
        @include scaled-spacer('margin','bottom','xxxxxl');
      }
    }
  }
}
main.main {
  h2 {
    @include scaled-spacer('margin','top','lg');
  }
  #breadcrumbs {
    @include scaled-spacer('margin','bottom','md');
    @include overline-sm;
    color: $brand-primary !important;
    a {
      color: $brand-primary !important;
    }
    &+h2 {
      margin-top: 0;
    }
  }
}
body:not(.single-tribe_events) {
  main.main {
    #breadcrumbs {
      @include media-breakpoint-up(md) {
        @include scaled-spacer-negative('margin','top','xl');
      }
    }
  }
}
