// WordPress Generated Classes
// http://codex.wordpress.org/CSS#WordPress_Generated_Classes


.wp-block-columns {
  @include make-row();
  margin-bottom: $grid-gutter-width;
  align-items: flex-start !important;
  gap: 0 !important;
  @include media-breakpoint-down(sm) {
    flex-direction: column;
  }
  .wp-block-column {
    @include make-col-ready();
    height: 100%;
    @include media-breakpoint-down(sm) {
      @include make-col(12);
      margin-bottom: $grid-gutter-width;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
.wp-block-image {
  figcaption {
    @include scaled-spacer('padding','bottom','sm');
    font-size: $font-size-sm;
    font-style: italic;
  }
}
.has-text-align-center {
  text-align: center;
}
// Media alignment
.alignnone {
  margin-left: 0;
  margin-right: 0;
  max-width: 100%;
  height: auto;
}
.aligncenter {
  display: block;
  margin: ($spacer / 2) auto;
  height: auto;
  text-align: center;
}
.alignleft,
.alignright {
  margin-bottom: ($spacer / 2);
  height: auto;
}
@include media-breakpoint-up(sm) {
  // Only float if not on an extra small device
  .alignleft {
    float: left;
    margin-right: ($spacer / 2);
  }
  .alignright {
    float: right;
    margin-left: ($spacer / 2);
  }
}

// Captions
.wp-caption {
  @extend .figure;
}
.wp-caption > img {
  @extend .figure-img;
  @extend .img-fluid;
}
.wp-caption-text {
  @extend .figure-caption;
}
.wp-caption-text {
  @extend .figure-caption;
}

// Text meant only for screen readers
.screen-reader-text {
  @extend .sr-only;
  @extend .sr-only-focusable;
}
#wpadminbar {
  a {
    color: inherit !important;
  }
}
