// Search form
.search-form {
  @extend .form-inline;
}
.search-form label {
  font-weight: normal;
  @extend .form-group;
}
.search-form .search-field {
  @extend .form-control;
}
.search-form .search-submit {
  @extend .btn;
  @extend .btn-secondary;
}


.form-control {
  padding: $input-padding-y $input-padding-x !important;
  font-weight: $input-font-weight !important;
  font-size: $input-font-size !important;
  background-color: $input-bg !important;
  &:focus {
		&::-webkit-input-placeholder {
		  color: transparent;
		}
		&:-moz-placeholder { /* Firefox 18- */
		  color: transparent;
		}
		&::-moz-placeholder {  /* Firefox 19+ */
		  color: transparent;
		}
		&:-ms-input-placeholder {
		  color: transparent;
		}
	}
	&.underlined {
    padding-left: 0 !important;
  	background-color: transparent !important;
  	border: none;
  	border-radius: 0;
  	border-bottom-style: solid;
  	border-bottom-width: 1px;
    &:focus {
  		&::-webkit-input-placeholder {
  		  color: transparent !important;
  		}
  		&:-moz-placeholder { /* Firefox 18- */
  		  color: transparent !important;
  		}
  		&::-moz-placeholder {  /* Firefox 19+ */
  		  color: transparent !important;
  		}
  		&:-ms-input-placeholder {
  		  color: transparent !important;
  		}
  	}
	}
}
label:not(.btn):not(.search-posts-label) {
  @include label;
}
button.stripped {
  @include transition($link-transition);
  border: none;
  background-color: transparent;
}
.gform_wrapper.gravity-theme {
  .gfield_label {
    @include label;
    .gfield_required {
      @include sr-only;
    }
  }
  .gfield {
    input[type=color],input[type=date],input[type=datetime-local],input[type=datetime],
    input[type=email],input[type=month],input[type=number],input[type=password],input[type=search],
    input[type=tel],input[type=text],input[type=time],input[type=url],input[type=week],
    select,textarea {
      @extend .form-control;
    }
    input#input_3_1 {
      @extend .underlined;
    }
    &.gfield_html {
      @include scaled-spacer('margin','top','sm');
      @include scaled-spacer('margin','bottom','sm');
      @include last-child-zero-margin;
      font-size: $font-size-sm;
    }
    &.gfield_error {
      input[type=color],input[type=date],input[type=datetime-local],input[type=datetime],
      input[type=email],input[type=month],input[type=number],input[type=password],input[type=search],
      input[type=tel],input[type=text],input[type=time],input[type=url],input[type=week],
      select,textarea {
        border-color: $error-color;
      }
      .gfield_validation_message, .validation_message {
        @include scaled-spacer('margin','top','xxs');
        background: transparent;
        border: none;
        font-size: $font-size-xs;
        font-style: italic;
        padding: 0;
      }
    }
  }
  .gform_button:not(#gform_submit_button_3) {
    @extend .btn;
  }
  .gform_validation_errors {
    @include color-block('red');
    @include full-padding-spacer('sm');
    text-align: center;
    .gform_submission_error {
      @include overline-sm;
      .gform-icon {
        @include sr-only;
      }
    }
  }
  form#gform_3 {
    @include d-flex;
    flex-direction: row;
    .gform_body {
      flex: 1 1 auto;
    }
    .gform_footer {
      margin: 0;
      padding: 0;
      .gform_button {
        @extend .stripped;
        margin: 0;
        padding: 1px 6px
      }
    }
    .validation_message {
      color: $white;
    }
  }
}
