.wp-full-width {
  @include wp-full-width-no-padding;
}
.fsf-block {
  @include scaled-spacer('padding','top','xl-xl');
  @include scaled-spacer('padding','bottom','xl-xl');
  @include scaled-spacer('margin','top','xl-xl');
  @include scaled-spacer('margin','bottom','xl-xl');
  @include media-breakpoint-up(md) {
    @include scaled-spacer('padding','top','xxl');
    @include scaled-spacer('padding','bottom','xxl');
    @include scaled-spacer('margin','top','xxl');
    @include scaled-spacer('margin','bottom','xxl');
  }
  &+.fsf-block:not(.bg-white) {
    @include scaled-spacer-negative('margin','top','xl-xl');
    @include media-breakpoint-up(md) {
      @include scaled-spacer-negative('margin','top','xxl');
    }
  }
  &.bg-white:not(.declaration-block) {
    padding-top: 0;
    &+.fsf-block.bg-white {
      @include scaled-spacer-negative('margin','top','xl-xl');
      @include media-breakpoint-up(md) {
        @include scaled-spacer-negative('margin','top','xxl');
      }
    }
  }
}
main.main {
  @include last-child-zero-margin;
  @include scaled-spacer('padding','top','xl-xl');
  @include scaled-spacer('padding','bottom','xl-xl');
  @include media-breakpoint-up(md) {
    @include scaled-spacer('padding','top','xxl');
    @include scaled-spacer('padding','bottom','xxl');
  }
  line-height: $line-height-main;
  :first-child {
    &.fsf-block {
      margin-top: 0;
      &.locations-tiles-block, &.info-tiles-block {
        padding-top: 0;
      }
      &.declaration-block {
        .home & {
          @include scaled-spacer-negative('margin','top','xl-xl');
          @include media-breakpoint-up(md) {
            @include scaled-spacer-negative('margin','top','xxl');
          }
        }
      }
    }
  }
  :last-child {
    &.fsf-block {
      margin-bottom: 0;
      &.bg-white {
        padding-bottom: 0;
      }
      &:not(.bg-white) {
        @include scaled-spacer-negative('margin','bottom','xxl');
      }
    }
  }
  .single-post & {
    &>article.hentry {
      &.has-post-thumbnail {
        @include scaled-spacer-negative('margin','top','xl-xl');
        @include media-breakpoint-up(md) {
          @include scaled-spacer-negative('margin','top','xxl');
        }
        #breadcrumbs {
          margin-top: 0 !important;
        }
      }
    }
  }
}
.cta-block,.infographic-block {
  @include scaled-spacer('padding','top','xxl');
  @include scaled-spacer('padding','bottom','xxl');
  .media {
    @include media-breakpoint-up(md) {
      @include last-child-zero-margin;
    }
  }
  .details {
    @include last-child-zero-margin;
    h2 {
      @include scaled-spacer('margin','bottom','md-lg');
      margin-top: 0;
      line-height: 1;
    }
  }
}
.cta-block {
  .media {
    figure {
      img {
        border-radius: $image-frame-radius;
      }
    }
  }
  .details {
    ul.links {
      @include list-normalize;
      @include media-breakpoint-up(lg) {
        @include list-inline-block;
      }
      li {
        @include scaled-spacer('margin','bottom','xss');
        @include media-breakpoint-up(lg) {
          @include scaled-spacer('margin','left','sm');
          &:first-child {
           margin-left: 0;
          }
        }
      }
    }
  }
}
.declaration-block {
  @include scaled-spacer('padding','top','md-lg');
  @include scaled-spacer('padding','bottom','sm');
  @include media-breakpoint-up(md) {
    @include scaled-spacer('padding','top','xxxl');
    @include scaled-spacer('padding','bottom','xxxl');
  }
  .details {
    @include serif-text;
    font-weight: $font-weight-med;
    font-size: $font-size-xl;
    text-align: center;
    h2 {
      @include scaled-spacer('margin','bottom','md');
      margin-top: 0;
    }
  }
}
.features-list-block {
  @include page-header-frame;
  @include scaled-spacer('padding','top','md-lg');
  @include scaled-spacer('padding','bottom','sm');
  @include media-breakpoint-up(md) {
    @include scaled-spacer('padding','top','xxxl');
    @include scaled-spacer('padding','bottom','xxxl');
  }
  &.bg-community {
    padding-top: 0;
  }
  &.bg-edged {
    @include media-breakpoint-down(sm) {
      @include scaled-spacer('padding','bottom','md-lg');
    }
  }
  figure.illustration {
    @include scaled-spacer('margin','bottom','md-lg');
    @include media-breakpoint-up(md) {
      @include scaled-spacer('margin','bottom','xxxl');
    }
    width: 100%;
    overflow: hidden;
    img {
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      min-width: 730px;
      @include media-breakpoint-up(sm) {
        min-width: 850px;
      }
      @include media-breakpoint-up(md) {
        min-width: 1000px;
      }
      @include media-breakpoint-up(lg) {
        min-width: 1400px;
      }
      @include media-breakpoint-up(xl) {
        min-width: 1600px;
      }
    }
  }
  .details {
    @include serif-text;
    font-weight: $font-weight-med;
    font-size: $font-size-lg;
    h2 {
      @include scaled-spacer('margin','bottom','md');
      margin-top: 0;
    }
  }
  .features {
    ul {
      @include list-normalize;
      @include media-breakpoint-up(lg) {
        columns: 2;
        column-gap: $grid-gutter-width;
      }
      li {
        @include scaled-spacer('margin','bottom','md');
        break-inside: avoid;
        @include media-breakpoint-between(md,lg) {
        font-size: $font-size-md;
        }
        @include media-breakpoint-up(lg) {
          @include scaled-spacer('margin','bottom','lg');
        }
        @include media-breakpoint-up(xl) {
          @include scaled-spacer('margin','bottom','xl');
        }
        span {
          flex-direction: row;
          align-items: center;
          .circle-icon {
            @include scaled-spacer('margin','right','sm');
            flex: 0 0 $circle-icon-size;
          }
        }
      }
    }
  }
}
.form-columns-block {
  @include color-block('white');
  &>.container {
    &>.row {
      @include media-breakpoint-up(lg) {
        flex-direction: row-reverse;
      }
    }
  }
  .form-column, .side-column {
    &>h2,&>h3 {
      @include scaled-spacer('margin','bottom','md-lg');
      @include resize-header('h3');
      margin-top: 0;
    }
  }
  @include media-breakpoint-down(md) {
    .side-column {
      @include scaled-spacer('margin','bottom','md-lg');
    }
  }
  .side-column {
    font-size: $font-size-sm;

    ul.contact-links {
      @include list-normalize;
      @include scaled-spacer('margin','bottom','sm-md');
      li {
        @include scaled-spacer('margin','bottom','xs-sm');
        a {
          @include d-flex;
          @include overline-sm;
          @include link-no-underline;
          align-items: center;
          i {
            @include scaled-spacer('margin','right','xs');
            font-size: $font-size-lg;
          }
        }
      }
    }
  }
}
.info-tiles-block {
  .info-tile {
    @include scaled-spacer('margin','bottom','lg');
    @include last-child-zero-margin;
    font-size: $font-size-md;
    &>article {
      @include media-breakpoint-up(md) {
        @include scaled-spacer('padding','left','xs-xx');
        @include scaled-spacer('padding','right','xs-xx');
      }
      @include media-breakpoint-up(lg) {
        @include scaled-spacer('padding','left','sm-md');
        @include scaled-spacer('padding','right','sm-md');
      }
      @include media-breakpoint-up(xl) {
        @include scaled-spacer('padding','left','md');
        @include scaled-spacer('padding','right','md');
      }
    }
    header {
      flex-direction: row;
      @include scaled-spacer('padding','bottom','sm');
      @include scaled-spacer('margin','bottom','sm');
      border-bottom: 4px solid $yellow;
      h3 {
        margin-bottom: 0;
      }
      i[class*="fa-"] {
        @include scaled-spacer('margin','left','sm');
        flex-shrink: 1;
        font-size: $h3-font-size;
      }
      &.tile-type-icon {
        align-items: center;
        h3 {
          flex-grow: 1;
        }
      }
      &.tile-type-image {
        flex-direction: column-reverse;
        figure {
          @include scaled-spacer('margin','bottom','sm-md');
          overflow: hidden;
          border-radius: $image-frame-radius;
          aspect-ratio: 9/5;
          img {
            object-fit: cover;
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}
.leadership-block {
  @include scaled-spacer('padding','top','lg');
  @include scaled-spacer('padding','bottom','lg');
  h2 {
    @include scaled-spacer('margin','bottom','sm-md');
    margin-top: 0 !important;
    text-align: center;
  }
  .leadership-tile {
    @include scaled-spacer('margin','bottom','md');
    a {
      @include link-no-underline;
      @include last-child-zero-margin;
      display: block;
      text-align: center;
      figure {
        overflow: hidden;
        @include scaled-spacer('margin','bottom','sm');
        border-radius: $image-frame-radius;
        aspect-ratio: 9/11;
        img {
          transition: all ease-in .1s;
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
      }
      h3 {
        @include scaled-spacer('margin','bottom','xs');
        @include scaled-spacer('padding','bottom','xs');
        background: url(../images/bg/date-accent.png) no-repeat center bottom;
        background-size: 40px auto;
        color: $green-bright;

      }
      p.role {
        @include overline-sm;
        color: $green;
        margin-bottom: 0;
      }
      @include hover-focus {
        figure {
          img {
            transform: scale(1.02);
          }
        }
        h3 {
          color: $green;
        }
      }
    }
  }
}
.news-highlights-block {
  h2.h1 {
    @include scaled-spacer('margin','bottom','md-lg');
    margin-top: 0;
    text-align: center;
  }
  .view-all {
    text-align: center;
    margin-bottom: 0;
    @include media-breakpoint-up(md) {
      @include scaled-spacer('margin','top','lg');
    }
  }
}
.statistics-block {
  @include scaled-spacer('padding','top','md-lg');
  @include scaled-spacer('padding','bottom','sm');
  @include media-breakpoint-up(md) {
    @include scaled-spacer('padding','top','xxxl');
    @include scaled-spacer('padding','bottom','xxxl');
  }
  .details {
    h2 {
      @include scaled-spacer('margin','bottom','xl-xl');
      text-align: center;
      margin-top: 0;
    }
    .stats-row {
      .stat {
        @include scaled-spacer('margin','bottom','sm-md');
        article {
          @include last-child-zero-margin;
          @include color-block('white');
          @include full-padding-spacer('md-lg');
          border-radius: $image-frame-radius;
          box-shadow: 0px 4px 9px rgba($black, 0.05);
          height: 100%;
          .overline {
            @include overline-sm;
            @include scaled-spacer('margin','bottom','sm');
          }
          h3 {
            @include scaled-spacer('margin','bottom','sm');
            @include scaled-spacer('padding','bottom','sm');
            border-bottom: 4px solid $yellow;
          }
        }
      }
    }
  }
}
