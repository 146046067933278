// Grid settings
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 1024px,
  xl: 1300px
);
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 992px,
  xl: 1252px
);

$main-sm-columns:       12;
$sidebar-sm-columns:    4;

$grid-gutter-width-base:  32px;
$grid-gutter-width:       $grid-gutter-width-base;
$grid-gutter-height:      $grid-gutter-width/2;

$base-gutter-heights: (
  base: $grid-gutter-height
);
$base-gutter-scales: (
  xxxs: .125, // 2
  xxs: .25,   // 4
  xss: .375,  // 6
  xs: .5,     // 8
  xs-xx: .625,// 10
  xs-sm: .75, // 12
  sm: 1,      // 16
  sm-sm: 1.25,// 20
  sm-md: 1.5, // 24
  md: 2,      // 32
  md-lg: 2.5, // 40
  lg: 3,      // 48
  lg-xl: 3.5, // 56
  xl-xl: 3.75,// 60
  xl: 4,      // 64
  xxl: 5,     // 80
  xxxl: 6,    // 96
  xxxxl: 8,   // 128
  xxxxxl: 10, // 160
  huge: 13,   // 208
  mega: 17,   // 272
  hugexl: 16.75,   // xlxl+huge
  megaxl: 22,   // xxl+huge
);

$searchbar-width:         120px;
$searchbar-width-closed:  70px;
$header-height:           98px;
$header-height-sm:        80px;
$ancillary-height:        40px;

/* COLORS
  --------------------------------------- */
$green:                 #38542A;
$green-bright:          #318129;
$red:                   #C83000;
$orange:                #F7A32C;
$yellow:                #FFE996;
$yellow-light:          #fefbea;
$blue:                  #B9E2F3;
$blue-light:            #F4FAFD;
$gray:                  #4E4F51;
$gray-light:            #F6F6F6;

$white:                 #fff;
$black:                 #000;
$black-near:            #3A3939;

$body-color:            $black-near;
$body-bg:               $white;
$error-color:           $red;

$brand-primary:         $green;
$brand-secondary:       $yellow;
$brand-teriary:         $orange;

$error-color:           $red;

$link-color:            $brand-primary;
$link-decoration:       none;
$link-hover-color:      $brand-primary;
$link-hover-decoration: underline;
$link-transition:       all .15s ease-in-out;
$link-transition-btn:   padding .15s ease-in-out, all .15s ease-in-out;
$link-transition-move:  transform .15s ease-in-out;
$link-transition-bars:  opacity .2s, transform .2s;

/* FONTS
  --------------------------------------- */
$font-family-sans:      Lato, Helvetica, Arial, sans-serif;
$font-family-serif:     Lora, serif;
$font-family-awesome:   'Font Awesome 6 Pro';
$font-family-base:      $font-family-sans;

$font-size-base:        1rem;       //  20
$font-size-xxs:         .6rem;      //  12
$font-size-xs:          .7rem;      //  14
$font-size-sm:          .8rem;      //  16
$font-size-md:          .9rem;      //  18
$font-size-lg:          1.2rem;     //  24
$font-size-xl:          1.4rem;     //  28

$h1-font-size:          3.6rem;     //  72
$h2-font-size:          2.8rem;     //  56
$h3-font-size:          1.6rem;     //  32
$h4-font-size:          1.2rem;     //  24
$h5-font-size:          1rem;       //  20
$h6-font-size:          .9rem;      //  18

$h1-font-size-sm:       2.6666rem;  //  48 (on smaller device)
$h1-display-lg:         4.8rem;     //  96
$h1-display-md:         4rem;       //  80

$font-weight-light:     300;
$font-weight-normal:    400;
$font-weight-med:       500;
$font-weight-semi:      600;
$font-weight-bold:      700;
$font-weight-black:     900;
$font-weight-base:      $font-weight-normal;

$line-height-base:      1.3;
$line-height-main:      1.4;

$headings-font-family:  $font-family-serif;
$headings-font-weight:  $font-weight-med;
$headings-line-height:  1.2;

$spaced-lettering:      .1em;

$image-frame-radius:    20px;
$circle-icon-size:      64px;


/* BUTTONS
  --------------------------------------- */
$btn-line-height:                1;
$btn-font-size:                  $font-size-md;
$btn-font-size-sm:               $font-size-xxs;
$btn-font-weight:                $font-weight-bold;
$btn-box-shadow:                 none;
$btn-focus-box-shadow:           none;
$btn-active-box-shadow:          none;
$btn-radius:                     30px;
$btn-bg:                         $yellow;
$btn-color:                      $brand-primary;

$btn-padding-y:                  .9em;
$btn-padding-x:                  1.2em;

$btn-padding-y-sm:               .75em;
$btn-padding-x-sm:               1em;

$btn-padding-y-stroke:           .5em;
$btn-padding-x-stroke:           .8em;


/* FORM INPUTS
  --------------------------------------- */
$input-font-size:                $font-size-md;
$input-font-weight:              $font-weight-base;
$input-line-height:              $btn-line-height;
$input-height:                   auto;
$input-padding-y:                .5em;
$input-padding-x:                .75em;

$custom-select-feedback-icon-padding-right: 0;
$custom-select-feedback-icon-position:      0;
$custom-select-padding-x:                   0;
$custom-select-indicator-padding:           0;

$input-bg:                       $white;
$input-disabled-bg:              $gray-light;
$input-color:                    $body-color;
$input-border-color:             $brand-primary;
$input-border-width:             3px;
$input-box-shadow:               none;

$input-border-radius:            10px;
$input-border-radius-lg:         $input-border-radius;
$input-border-radius-sm:         $input-border-radius;

$input-focus-border-color:       $green-bright;
$input-focus-box-shadow:         none;

$input-placeholder-color:        rgba($input-color,.6);
$input-plaintext-color:          $input-placeholder-color;

