.categories-block {
  .categories {
    @include category-filters;
  }
}
.articles-grid {
  article {
    @include make-col-ready();
    @include make-col(12);
    @include scaled-spacer('margin','bottom','sm-md');
    @include media-breakpoint-up(md) {
      @include scaled-spacer('margin','bottom','md');
      @include make-col(6);
    }
    @include media-breakpoint-up(lg) {
      @include scaled-spacer('margin','bottom','md-lg');
    }
    a.article-link {
      @include color-block('gray-light');
      @include link-no-underline;
      border-radius: $image-frame-radius;
      height: 100%;
      text-align: center;
      figure {
        @include featured-image-frame('gray-light');
        overflow: hidden;
        margin: 0;
        aspect-ratio: 9/5;
        img {
          transition: all ease-in .1s;
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
      }
      .entry-details {
        @include full-padding-spacer('md-lg');
        @include scaled-spacer('padding','top','sm');
        @include last-child-zero-margin;
        font-size: $font-size-sm;
        color: $body-color;
        .entry-meta {
          @include overline-sm;
          @include scaled-spacer('margin','bottom','xs');
        }
        h3 {
          @include scaled-spacer('margin','bottom','xs');
        }
      }

      @include hover-focus {
        box-shadow: 0px 0px 5px 0px rgba($blue, 0.1);
        figure {
          img {
            transform: scale(1.02);
          }
        }
        h3 {
          color: $green-bright;
        }
      }
    }
  }
  &.three-by {
    article {
      @include media-breakpoint-up(lg) {
        @include make-col(4);
      }
    }
  }
}

.page-header.post-header {
  @include color-block('blue-light');
  .details {
    @include scaled-spacer('margin','top','md-lg');
    @include scaled-spacer('margin','bottom','xxxl');
    text-align: center;
    @include media-breakpoint-up(md) {
      @include scaled-spacer('margin','top','xxl');
      @include make-col(12);
      @include make-col-offset(0);
    }
    @include media-breakpoint-up(lg) {
      @include make-col(10);
      @include make-col-offset(1);
    }
    @include media-breakpoint-up(xl) {
      @include make-col(8);
      @include make-col-offset(2);
    }
  }
  &.img-featured {
    h1.page-title {
      @include scaled-spacer('margin','bottom','md');
    }
    .details {
      @include scaled-spacer('margin','bottom','huge');
      @include media-breakpoint-up(lg) {
        @include scaled-spacer('margin','bottom','mega');
      }
    }
  }
}
.single-post {
  main.main {
    &>article.hentry {
      section.featured-img {
        @include scaled-spacer-negative('margin','top','hugexl');
        @include media-breakpoint-up(lg) {
          @include scaled-spacer-negative('margin','top','megaxl');
        }
        figure {
          @include featured-image-frame('white');
        }
      }
    }
  }
}
.single-leadership {
  main.main {
    &>article.hentry {
      .headshot {
        figure {
          img {
            border-radius: $image-frame-radius;
          }
        }
      }
      .entry-content {
        .overline-sm {
          @include scaled-spacer('margin','bottom','sm');
          @include scaled-spacer('padding','bottom','sm');
          border-bottom: 4px solid $yellow;
        }
      }
    }
  }
}


/* NAVS
  --------------------------------------- */
nav.posts-navigation {
  @include scaled-spacer('margin','top','md-lg');
  @include media-breakpoint-up(md) {
    @include scaled-spacer('margin','top','xxxl');
  }
  .nav-links {
    @include make-row();
    &>div {
      @include make-col-ready();
      @include make-col(6);
      a {
        @extend .btn;
      }
      &.nav-previous {
        a {
          @extend .btn-back;
        }
      }
      &.nav-next {
        margin-left: auto;
        text-align: right;
      }
    }
  }
}
