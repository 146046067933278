.single-tribe_events {
  main.main {
    article.tribe-events-single {
      section.featured-img {
        @include scaled-spacer-negative('margin','top','hugexl');
        @include media-breakpoint-up(lg) {
          @include scaled-spacer-negative('margin','top','megaxl');
        }
      }
      #breadcrumbs {
        @include scaled-spacer('margin','top','xs-sm');
        @include scaled-spacer('margin','bottom','xs-sm');
        @include media-breakpoint-up(md) {
          @include scaled-spacer('margin','bottom','md');
        }
      }
      .event-content {
        @include media-breakpoint-up(md) {
          flex-direction: row-reverse;
        }
        .details {
          &>div {
            @include color-block('gray-light');
            @include last-child-zero-margin;
            @include scaled-spacer('margin','bottom','sm-md');
            @include full-padding-spacer('sm-md');
            font-size: $font-size-md;
            @include media-breakpoint-up(xl) {
              @include full-padding-spacer('md-lg');
            }
            border-radius: $image-frame-radius;
            ul.listing {
              @include list-normalize;
              li {
                @include scaled-spacer('margin','bottom','sm');
                line-height: 1.3;
                span {
                  flex-direction: row;
                  .fa-icon-color {
                    flex: 0 0 $circle-icon-size*.4;
                  }
                }

              }
            }
            p {
              @include scaled-spacer('margin','top','sm-md');
              margin-bottom: 0;
              &.ticket-details {
                font-weight: $font-weight-semi;
              }
            }
          }
        }
        .description {
          @include last-child-zero-margin;
          :first-child {
            margin-top: 0;
          }
        }
      }
    }
  }
}
.fsf-event-block {
  @include color-block('gray-light');
  @include scaled-spacer('padding','top','md-lg');
  @include scaled-spacer('padding','bottom','md-lg');
  article {
    @include main-column-width-wide;
    .featured-img,.details-wrap {
      @include make-col-ready();
      @include make-col(12);
    }
    .featured-img {
      @include scaled-spacer('margin','bottom','sm-md');
      .date-widget {
        left: 32px;
        @include media-breakpoint-up(md) {
          left: 40px;
        }
      }
    }
    .details-wrap {
      h2 {
        @include resize-header('h3');
        margin-top: 0;
        a {
          @include link-no-underline;
        }
      }
      .entry-content {
        @include last-child-zero-margin;
      }
    }
  }
  &:not(.tribe-events-calendar-list__event-row--featured) {
    .featured-img,.details-wrap {
      @include media-breakpoint-up(md) {
        @include make-col(6);
      }
    }
    .featured-img {
      @include media-breakpoint-up(md) {
        margin-bottom: 0;
      }
    }
    &+.fsf-event-block:not(.tribe-events-calendar-list__event-row--featured) {
      padding-top: 0;
    }
  }
  &.tribe-events-calendar-list__event-row--featured {
    @include color-block('white');
    article {
      .details-wrap {
        .overline {
          background: url(../images/bg/event-accent.png) repeat-x right center;
          background-size: auto 4px;
          span {
            @include color-block('white');
            @include scaled-spacer('padding','right','xs-sm');
          }
        }
        h2 {
          @include resize-header('h1');
        }
      }
      @include media-breakpoint-up(md) {
        .featured-img {
          @include scaled-spacer('margin','bottom','md-lg');
        }
        .details {
          @include make-row();
          h2,.entry-content {
            @include make-col-ready();
            @include make-col(6);
          }
        }
      }
    }
  }
}
section.featured-img {
  position: relative;
  figure {
    border-radius: $image-frame-radius;
    margin-bottom: 0;
    img {
      border-radius: $image-frame-radius;
    }
  }
}

.date-widget {
  @include color-block('white');
  @include full-padding-spacer('xs-xx');
  @include scaled-spacer('padding','left','xss');
  color: $brand-primary;
  display: block;
  min-width: 72px;
  position: absolute;
  top: 12px;
  left: 16px;
  border: 3px solid $orange;
  border-radius: $image-frame-radius;
  font-weight: $font-weight-black;
  font-size: $h3-font-size;
  line-height: 1;
  span {
    display: block;
    &.month {
      @include scaled-spacer('margin','bottom','xxs');
      @include scaled-spacer('padding','bottom','xs-sm');
      @include serif-text;
      font-size: $font-size-md;
      font-weight: $font-weight-bold;
      background: url(../images/bg/date-accent.png) no-repeat left bottom;
      background-size: auto 4px;
    }
  }
  @include media-breakpoint-up(md) {
    @include full-padding-spacer('sm-md');
    @include scaled-spacer('padding','left','sm');
    @include scaled-spacer('padding','bottom','sm-sm');
    min-width: 118px;
    top: 20px;
    left: 24px;
    font-size: $h2-font-size;
    span {
      &.month {
        @include scaled-spacer('padding','bottom','sm');
      }
    }
  }
}
.ecs-event-list {
  @include list-normalize;
  @include scaled-spacer('margin','top','lg');
  li {
    @extend .row;
    @include scaled-spacer('margin','bottom','md-lg');
    .duration {
      display: none;
    }
    &>a,h4 {
      @include make-col-ready();
      @include make-col(12);
      @include media-breakpoint-up(md) {
        @include make-col(6);
      }
    }
    &>a {
      order: 0;
    }
    h4 {
      order: 1;
      font-size: $h3-font-size;
    }
  }
}
