html {
  font-size: 18px;
  height: 100%;
  scroll-behavior: smooth;
  scroll-padding-top: $header-height-sm;
  @include media-breakpoint-up(md) {
    scroll-padding-top: $header-height;
    font-size: 20px;
  }
  body {
    @include color-block('white');
  }
}
a {
  @include transition($link-transition);
  .readmore {
    @include overline-sm;
    font-weight: $font-weight-bold;
    &:after {
      @include transition($link-transition-btn);
      @include fa-font-solid;
      @include scaled-spacer('margin','left','xss');
      content: "\f178";
    }
  }
  @include hover-focus {
    .readmore:after {
      @include scaled-spacer('margin','left','xs-xx');
    }
  }
  &#skip-link {
    position:fixed;
    left:-999px;
    top: 0;
    z-index:1001;
    width:1px;
    height:1px;
    overflow:hidden;
    &:active, &:focus {
      top:8px;
      left: 8px;
      width: auto;
      height: auto;
      overflow:auto;
    }
  }
}
img {
  @include img-fluid;
}
.row {
  &.align-center {
    align-items: center;
  }
  &.justify-center {
    justify-content: center;
  }
}
.circle-icon {
  width: $circle-icon-size;
  height: $circle-icon-size;
  line-height: $circle-icon-size;
  vertical-align: middle;
  text-align: center;
  border-radius: 50%;
}

/* FONTS
  --------------------------------------- */
h1,.h1,h2,.h2,h3,.h3 {
  @include scaled-spacer('margin','bottom','sm');
}
h4,.h4,h5,.h5,h6,.h6 {
  @include scaled-spacer('margin','bottom','sm');
}
h1,.h1 {
  @include media-breakpoint-down(md) {
    font-size: $h1-font-size-sm;
  }
}
.overline {
  @include overline;
}
.overline-sm {
  @include overline-sm;
}
p{
  @include scaled-spacer('margin','bottom','sm-md');
}
ul,ol {
  @include scaled-spacer('margin','bottom','md');
  padding-left: 1.25rem;
  &>li {
    @include scaled-spacer('margin','bottom','xss');
  }
}
blockquote {
  @include last-child-zero-margin;
  @include serif-text;
  @include scaled-spacer('padding','left','md-lg');
  @include scaled-spacer('margin','top','md-lg');
  @include scaled-spacer('margin','bottom','md-lg');
  border-left-style: solid;
  border-left-width: 18px;
  font-size: $h4-font-size;
  font-style: italic;
  line-height: $headings-line-height;
}

.bg-texture {
  background-image: url(../images/bg/texture.png);
  background-repeat: repeat;
  background-position: center center;
}

